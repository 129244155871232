<template>
	<div class="container">
		<div class="flex flex--between flex--top">
			<h1 class="main__title">Все новости</h1>
		</div>

		<div class="news__wrapper" v-if="news">
			<template v-if="news.length > 0">
				<div class="flex--grow w-0">
					<ul class="news__list">
						<li class="news__item" v-for="(item, index) in news" :key="index">
							<router-link
								:to="{ name: setLink, params: { id: item.id } }"
								class="news__link"
							>
								<div class="news__item-image-box">
									<img
										:src="item.thumb"
										:alt="item.name"
										class="news__item-image"
									/>
								</div>
								<div class="news__content">
									<time class="news__time">{{ item.createdAt }}</time>
									<h3 class="news__title news__title--hider">{{ item.name }}</h3>
									<div
										class="news__text editor news__text--hider"
										v-html="text(item.description)"
									></div>
								</div>
							</router-link>
						</li>
					</ul>
					<div
						class="news__show-more flex flex--center"
						v-if="pagination.pageCount != pagination.currentPage"
					>
						<button class="button" @click="nextPage">Показать ещё</button>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="flex flex--center flex--grow">
					<p class="main__text text-center">Новостей нет</p>
				</div>
			</template>

			<div class="news__filter-wrapper" v-click-outside="hideFilter">
				<button class="button news__filter-button" @click="showFilterBox">
					Фильтр
				</button>
				<button
					class="button news__filter-button-mobile"
					@click="showMobileFilter"
				>
					Фильтр
				</button>
				<div class="news__filter-mobile-wrapper" :class="{ active: filter }">
					<button class="filter__close-mobile" @click="hideMobileFilter">
						<span class="visually-hidden">Зарыть поиск</span>
					</button>
					<transition name="filter__slide">
						<form
							class="news__filter-box"
							@submit.prevent="setDate({ refresh: true })"
							v-show="filter"
						>
							<button class="header__close" type="button" @click="hideFilter">
								<span class="visually-hidden">Зарыть поиск</span>
							</button>
							<fieldset class="news__filter-fieldset">
								<p class="news__filter-title">Показать</p>

								<span class="visually-hidden">С</span>
								<datepicker
									:language="ru"
									v-model="dateFrom"
									:disabled-dates="state.disabledDatesFrom"
									@input="selectedDate"
									class="news__input"
								></datepicker>

								<span class="visually-hidden">По</span>
								<datepicker
									:language="ru"
									v-model="dateNow"
									:disabled-dates="state.disabledDatesTo"
									@input="selectedDate"
									class="news__input"
								></datepicker>

								<label class="checkbox">
									<input
										type="checkbox"
										class="visually-hidden"
										v-model="allNews"
										@change="selectAll"
									/>
									<span class="checkbox__indicator"></span>
									<span class="checkbox__text">За все время</span>
								</label>
							</fieldset>

							<div class="news__filter-button-box">
								<button class="button" type="submit">Показать</button>
							</div>
						</form>
					</transition>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { ru } from 'vuejs-datepicker/dist/locale'

const date = new Date()

export default {
	name: 'NewsAll',

	components: {
		Datepicker,
	},

	data() {
		return {
			ru: ru,
			dateNow: parseInt(this.$moment(date).format('x')),
			dateFrom: date.setMonth(date.getMonth() - 1),
			filter: false,
			allNews: true,
			page: 1,
			state: {
				disabledDatesFrom: {
					from: new Date(),
				},
				disabledDatesTo: {
					from: new Date(),
					to: date,
				},
			},
		}
	},

	methods: {
		text(description) {
			/*
			if(description !== undefined || description.length > 249){
				return description.slice(0, 289) + '...'
			} else {
				return description
			}
			*/
			let text = description
			if (text && text.length > 259) {
				text = this.anot(text.slice(0, 259))
				/*
				let words = text.slice(0, 259).trim().split(' ')
				//
				let lastWord = words[words.length - 1]
				delete words[words.length - 1]
				//
				if(lastWord.length < 4) {
					lastWord += words[words.length - 2]
					delete words[words.length - 2]
				}
				
				text = words.join(' ') + `${ lastWord }...` //words.join(' ') + `<span class="news__text--hider">${ lastWord }...</span>`
				*/
			}
			text =
				'<p class="news__text--hider">' + text.replace(/<[^>]+>/g, '') + '</p>'
			return text
		},
		anot(text) {
			const list = text.split(' ')
			const half = Math.ceil(list.length / 2)

			const firstHalf = list.splice(0, half).join(' ')
			const secondHalf = list
				.splice(-half)
				.join(' ')
				.replace(/[,.!?;:-]/g, '')
			return firstHalf + ' ### ' + secondHalf
		},
		getNews(data) {
			this.$store.dispatch('GET_NEWS', {
				...this.$route.meta,
				type: 'list',
				perPage: 4,
				...data,
			})
		},

		selectedDate() {
			this.allNews = false

			this.state = {
				disabledDatesFrom: {
					from: new Date(this.dateNow),
				},
				disabledDatesTo: {
					from: new Date(),
					to: new Date(this.dateFrom),
				},
			}
		},

		selectAll() {
			this.dateNow = new Date()
			this.dateFrom = date
		},

		setDate(data) {
			if (!this.allNews) {
				const from = this.dateFrom.toISOString()
				const to = this.dateNow.toISOString()

				this.getNews({ date: { from, to }, ...data })
			} else {
				this.getNews({ ...data })
			}
		},

		nextPage() {
			// let page = parseInt(this.pagination.currentPage)
			this.page = this.page + 1
			this.setDate({ page: this.page, refresh: false })
		},

		showFilterBox() {
			if (this.filter) {
				this.filter = false
			} else {
				this.filter = true
			}
		},

		showMobileFilter() {
			if (this.filter) {
				this.filter = false
				this.$store.dispatch('SET_BLOCK_SCROLL', false)
			} else {
				this.filter = true
				this.$store.dispatch('SET_BLOCK_SCROLL', true)
			}
		},

		hideFilter() {
			this.filter = false
		},

		hideMobileFilter() {
			this.filter = false
			this.$store.dispatch('SET_BLOCK_SCROLL', false)
		},
	},

	computed: {
		setLink() {
			const meta = this.$route.meta
			let link = null

			if (meta.holder) {
				link = 'NewsItemHolder'
			} else if (meta.partner) {
				link = 'NewsItemPartner'
			}

			return link
		},

		news() {
			const news = this.$store.getters.NEWS.list

			return news
		},

		pagination() {
			const news = this.$store.getters.NEWS
			return {
				pageCount: news.pageCount,
				currentPage: news.currentPage,
			}
		},
	},

	mounted() {
		this.getNews({ refresh: true })
	},
}
</script>
